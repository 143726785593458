/* eslint-disable jsx-a11y/control-has-associated-label */

import React from "react"
import {
  MdPhoneIphone,
  MdHiking,
  MdLocationPin,
  MdOutlineIntegrationInstructions,
  MdQrCode,
} from "react-icons/md"
import { FaHome, FaMap, FaHiking, FaUserPlus } from "react-icons/fa"

import { Layout, Seo } from "../../layout"
import { FindOutMoreButton, IPhone, img } from "../../components"

export default function IndexPage(props) {
  const iphone = "hidden sm:block flex-none w-40 ml-16 mr-4 mt-4"
  const product = "sm:my-12 sm:px-8 py-6 rounded max-w-5xl mx-auto"
  const product_name =
    "flex justify-left items-center mb-4 text-base sm:text-lg font-bold"
  const paragraph = "my-6"
  const table = "mt-4 mb-2 table-auto"
  const cell =
    "px-3 sm:px-6 py-1 border border-gray-400 bg-gray-200 text-sm sm:text-base"

  return (
    <Layout language="no" path={props.path}>
      <Seo title="Products" />

      <div className="mt-12 p-6 md:p-16">
        <div id="app" className={product + " flex"}>
          <div>
            <div className={product_name}>
              <Icon>
                <MdPhoneIphone className="w-full h-full" />
              </Icon>
              <div>Trailguide-appen</div>
            </div>
            <p className={paragraph}>
              Trailguide er en app for å dele, finne og navigere
              utendørsaktiviteter. Innholdet er lagt inn av brukerne og har mye
              innhold over hele Europe. Aktivitetene som er tilgjengelig "ut av
              boksen" i Trailguide er sykkelrelaterte stier og turer.
            </p>
            <table className={table}>
              <tbody>
                <tr>
                  <td className={cell}>Trailguide-app</td>
                  <td className={cell}>Gratis</td>
                </tr>
              </tbody>
            </table>

            <MoreButton to="/products/app" className="mr-4">
              Finn ut mer
            </MoreButton>

            <MoreButton href="https://trailguide.net" target="trailguide.net">
              Åpne Trailguide
            </MoreButton>
          </div>
          <IPhone
            alt="An app to find and share outdoor activities"
            src={img("/website/trailguide-iphone-world-4.jpeg")}
            className={iphone}
          />
        </div>

        <div id="white-label" className={product + " flex"}>
          <div>
            <div className={product_name}>
              <Icon>
                <MdHiking className="w-full h-full" />
              </Icon>
              <div>White-Label app</div>
            </div>
            <p className={paragraph}>
              Vi tilbyr skreddersydde white-label apper for din resort eller
              destinasjon. Ha full kontroll over innholdet du presenterer til
              dine gjester og besøkende ved å ha din egen app. Innholdet du
              presenterer til dine gjester vil være begrenset kun til relevante
              aktiviteter.
            </p>

            <MoreButton to="/products/whitelabel" className="mr-4">
              Finn ut mer
            </MoreButton>

            <MoreButton href="https://trysil.our.guide" target="whitelabel">
              Trysil Tour Guide
            </MoreButton>
          </div>
          <IPhone
            alt="An app to find and share outdoor activities"
            src={img("website/trailguide-iphone-sandbox-icons.jpg")}
            className={iphone}
          />
        </div>

        <div id="integration" className={product + " flex items-start"}>
          <div>
            <div className={product_name}>
              <Icon>
                <MdOutlineIntegrationInstructions className="w-full h-full" />
              </Icon>
              <div>Trailguide Integrasjon</div>
            </div>
            <p className={paragraph}>
              Integrer innholdet ditt på dine websider direkte fra Trailguide
              sin database. Plugin-modulen er superrask, tar liten tid å laste
              inn og er veldig fleksibel.
            </p>
            <p className={paragraph}>
              Du kan vise dine aktiviteter som et interaktivt kart eller som
              egne kort med turinformasjon. Ved å klikke på en aktivitet åpnes
              et egen vindu med all tilgjengelig informasjon om aktiviteten,
              inkludert bilder, video, anmeldelser og førerapporter.
            </p>
            <p className={paragraph}>
              Kartet kan utvides av brukeren til å dekke hele skjermen og hver
              aktivitet kan åpnes direkte i Trailguide-appen fra plugin-modulen.
            </p>
            <table className={table}>
              <tbody>
                <tr>
                  <td className={cell}>Trailguide Integrasjon</td>
                  <td className={cell}>kr 5.000 per domene/år</td>
                </tr>
              </tbody>
            </table>
            <MoreButton to="/products/plugin">Finn ut mer</MoreButton>
          </div>
          <div className="hidden sm:block ml-8 mt-16">
            <pre className="bg-blue-900 text-blue-100 px-6 py-4 mb-4 text-sm rounded shadow-md">
              {/* prettier-ignore */}
              {`<trailguide-plugin
 license="your_license_key"
 content="8064,6985,6816,8173"
 mode="carousel">
</trailguide-plugin>
`.trim()}
            </pre>
            <pre className="bg-blue-900 text-blue-100 px-6 py-4 text-sm rounded shadow-md">
              {/* prettier-ignore */}
              {`<trailguide-plugin
   license="your_license_key"
   content="7783,8064"
   lang="no"
   mode="full">
</trailguide-plugin>
  `.trim()}
            </pre>
          </div>
        </div>

        <div id="presence" className={product + " flex"}>
          <div>
            <div className={product_name}>
              <Icon>
                <MdLocationPin className="w-full h-full" />
              </Icon>
              <div>Tilstedeværelse i Trailguide-appen</div>
            </div>
            <p className={paragraph}>
              Hvis du jobber i en offisiell turistregion, destinasjon, resort
              eller en annen lokal bedrift som tilbyr tjenester gjester og
              besøkende kanskje trenger, så kan du legge til bedriften din på
              kartet i Trailguide.
            </p>
            <table className={table}>
              <tbody>
                <tr>
                  <td className={cell}>Lokal tjeneste</td>
                  <td className={cell}>kr 4.000 per år</td>
                </tr>
                <tr>
                  <td className={cell}>Resort</td>
                  <td className={cell}>kr 8.000 per år</td>
                </tr>
                <tr>
                  <td className={cell}>Destination</td>
                  <td className={cell}>kr 15.000 per år</td>
                </tr>
                <tr>
                  <td className={cell}>Region</td>
                  <td className={cell}>kr 30.000 per år</td>
                </tr>
              </tbody>
            </table>
            <MoreButton to="/no/products/presence">Finn ut mer</MoreButton>
          </div>
          <IPhone
            alt="Ensure your presence on Trailguide"
            src={img("/website/trailguide-iphone-presence2.png")}
            className={iphone}
          />
        </div>

        <div id="landing_page" className={product + " flex"}>
          <div>
            <div className={product_name}>
              <Icon>
                <FaHome className="w-full h-full" />
              </Icon>
              <div>Trailguide Landingsside</div>
            </div>
            <p className={paragraph}>
              En landingsside i Trailguide vil vise informasjon om din region,
              destinasjon, eller resort sammen med tilgjengelige turer og
              aktiviteter. Landingssiden kan åpnes direkte gjennom en link, ved
              å skanne en QR-kode eller ved å klikke symbolet ditt på kartet i
              Trailguide.
            </p>
            <p className={paragraph}>
              Hvis du er en offisiell turistregion eller destinasjon kan du også
              liste opp lokale tjenester slik som hoteller, campingplasser,
              sportsbutikker, sykkelverksteder, guide-selskaper,
              shuttle-selskaper, restauranter osv. på landingssiden din.
            </p>
            <table className={table}>
              <tbody>
                <tr>
                  <td className={cell}>Landingsside</td>
                  <td className={cell}>kr 5.000 per år</td>
                </tr>
                <tr>
                  <td className={cell}>Lokale tjenester</td>
                  <td className={cell}>kr 1.000 per bedrift/år</td>
                </tr>
              </tbody>
            </table>
            <MoreButton to="/products/landingpage">Finn ut mer</MoreButton>
          </div>
          <IPhone
            alt="Your own landing page on Trailguide"
            src={img("/website/trailguide-iphone-landingpage2.jpeg")}
            className={iphone}
          />
        </div>

        <div id="wall_map" className={product + " flex items-start"}>
          <div>
            <div className={product_name}>
              <Icon>
                <FaMap className="w-full h-full" />
              </Icon>
              <div>Skreddersydde veggkart</div>
            </div>
            <p className={paragraph}>
              Vi designer og printer 3D-lignende topografiske kart for
              resepsjonen eller butikkveggen din. Kartene har din egen unike
              QR-kode som åpner landingssiden din i Trailguide om den skannes.
              Vi kan også levere klistremerker med QR-koder for landingssiden
              din så du kan plassere disse hvor du måtte ønske innenfor
              bedriften din.
            </p>
            <table className={table}>
              <tbody>
                <tr>
                  <td className={cell}>Skreddersydd veggkart</td>
                  <td className={cell}>kr 20.000</td>
                </tr>
                <tr>
                  <td className={cell}>Ekstra eksemplar</td>
                  <td className={cell}>kr 4.000 per stykk</td>
                </tr>
                <tr>
                  <td className={cell}>QR-code sticker pack</td>
                  <td className={cell}>Gratis</td>
                </tr>
              </tbody>
            </table>
          </div>
          <img
            className="hidden sm:block flex-none w-96 ml-16 mr-4 mt-16 rounded shadow"
            alt="Printed wall maps with QR codes"
            src={img("/website/trailguide-scan-map.jpg")}
          />
        </div>

        <div id="activity_manager" className={product}>
          <div className={product_name}>
            <Icon>
              <FaHiking className="w-full h-full" />
            </Icon>
            <div>Activity Manager</div>
          </div>
          <p className={paragraph}>
            Legg til hvilken som helst type aktivitet du vil. Ha full kontroll
            over innholdet du presenter dine gjester og besøkende. Håndplukk de
            aktivitetene du vil vise frem.
          </p>
          <p className={paragraph}>
            Er du en offisiell turistregion, destinasjon eller resort kan du
            også "brande" dine aktiviteter med din navn og din logo i stedet for
            å vise personen som har lagt den til i databasen.
          </p>
          <table className={table}>
            <tbody>
              <tr>
                <td className={cell}>Activity Manager</td>
                <td className={cell}>kr 5.000 per år</td>
              </tr>
              <tr>
                <td className={cell}>Størrelse på dataset</td>
                <td className={cell}>kr 100 per aktivitet/år</td>
              </tr>
              <tr>
                <td className={cell}>Branding av aktiviteter</td>
                <td className={cell}>kr 5.000 per år</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div id="collection_manager" className={product}>
          <div className={product_name}>
            <Icon>
              <MdQrCode className="w-full h-full" />
            </Icon>
            <div>Collection Manager</div>
          </div>
          <p className={paragraph}>
            Lag dine egne "collections" (eller skreddersydde digital kart om du
            vil) av aktiviteter skreddersydd for ulike brukergrupper og typer
            bruk. Hver av disse kan aksesseres gjennom å skanne en QR-kode,
            åpnes via en link, eller integreres i dine websider.
          </p>
          <table className={table}>
            <tbody>
              <tr>
                <td className={cell}>Collection Manager</td>
                <td className={cell}>kr 5.000 per år</td>
              </tr>
            </tbody>
          </table>
          <MoreButton to="/pro/map">Finn ut mer</MoreButton>
        </div>

        <div className={product}>
          <div className={product_name}>
            <Icon>
              <FaUserPlus className="w-full h-full" />
            </Icon>
            <div>Trailguide Adminbruker</div>
          </div>
          <p className={paragraph}>
            En lisens til Trailguide-portal kommer med 1 adminbruker. Du kan
            legge til flere brukere for å dele tilgang og samarbeide om
            innholdet deres.
          </p>
          <table className={table}>
            <tbody>
              <tr>
                <td className={cell}>Ekstra adminbruker</td>
                <td className={cell}>kr 2.000 per bruker/år</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  )
}

function Icon(props) {
  return (
    <div className="w-8 h-8 p-1 sm:w-10 sm:h-10 sm:p-2 mr-4 bg-blue-500 text-blue-100 rounded-full">
      {props.children}
    </div>
  )
}

function MoreButton(props) {
  return <FindOutMoreButton className="mt-8" {...props} />
}
